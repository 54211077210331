

html {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Nanum Gothic", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

html, body, #root {
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.tooltip {
  position: fixed;
  display: none;
  border-radius: 0 10px 10px 0;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateX(68px) /* Ajusta a posição do tooltip para a direita */
}

/* .tooltip-container:hover .tooltip {
  opacity: 1;
} */

.tooltip.visible {
  display: flex;
  opacity: 1;
}


.no-border {
  border: none;
  outline: none; /* Remove a borda que aparece quando o botão está focado */
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

/* Adicionalmente, remova o estilo do botão ao focar */
.no-border:focus {
  outline: none;
}

.image-container {
  width: 120%;
  overflow: hidden;
  position: relative;
  margin: -10%;
}

.image-container {
  width: 120%;
  overflow: hidden;
  position: relative;
  margin: -10%;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Telas grandes (desktops e acima) */
@media (min-width: 1024px) {
  .image-container {
      height: 550px;
  }
}

/* Telas médias (tablets) */
@media (min-width: 768px) and (max-width: 1023px) {
  .image-container {
      height: 500px;
      margin: -30%;
  }
  .image-container img {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* Telas pequenas (celulares) */
@media (max-width: 767px) {
  .image-container {
      height: 250px;
  }
  .image-container img {
    width: 200%;
    height: 200%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
